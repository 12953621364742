<template>
  <div>
    <a-modal
      v-model="isShow"
      width="400px"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="取消订单"
      @ok="handleSubmit"
    >
      <p>确认取消吗？</p>
      <a-row>
        <a-col :span="4">
          <span>备注:</span>
        </a-col>
        <a-col :span="18">
          <a-textarea
            allow-clear
            :max-length="200"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            @change="changeRemark"
          />
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { cancelAgentPurchaseOrder } from '@/api/agent_purchase_order'
export default {
  name: 'CancelAgentPurchaseOrder',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      remark: '',
      submitting: false,
      form: this.$form.createForm(this, { name: 'agent_purchase_order' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    changeRemark(e) {
      this.remark = e.target.value
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      cancelAgentPurchaseOrder(this.id, { remark: this.remark }).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed')
        }
        this.submitting = false
      })
    }
  }
}
</script>

<style scoped>

</style>
